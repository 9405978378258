import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Fragment } from "react";


const Skills = (props) => {
    return (
        <Fragment>
            <Grid item xs={12} style={{ marginTop: "40px" }} />
            <Grid item xs={3} />
            <Grid item xs={2} style={{ textAlign: "center" }}>
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg" alt="" className="skills" />
                <Typography variant="h6">JavaScript</Typography>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "center" }}>
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg" alt="" className="skills" />
                <Typography variant="h6">Python</Typography>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "center" }}>
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/csharp/csharp-original.svg" alt="" className="skills" />
                <Typography variant="h6">C#</Typography>
            </Grid>
            <Grid item xs={3} />

            <Grid item xs={12} style={{ marginTop: "30px" }} />
            <Grid item xs={3} />
            <Grid item xs={2} style={{ textAlign: "center" }}>
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg" alt="" className="skills" />
                <Typography variant="h6">React</Typography>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "center" }}>
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original.svg" alt="" className="skills" />
                <Typography variant="h6">PostgreSQL</Typography>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "center" }}>
                <img src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original-wordmark.svg" alt="" className="skills" />
                <Typography variant="h6">Node.js</Typography>
            </Grid>
            <Grid item xs={3} />
        </Fragment>
    );
}

export default Skills;