import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ReceiptIcon from "@material-ui/icons/Receipt";
import Footer from "./components/Footer";
import Skills from "./components/Skills";
import Resume from "./Resume.pdf";

const BlueText = withStyles({
  root: {
    background: "-webkit-linear-gradient(0deg, #007EFE 20%, #0059B3 90%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontWeight: "bolder"   
  }
})(Box);



function App() {
  return (
    <div>
        <Grid container spacing={2} style={{width: "100%", margin: 0, marginTop: "50px"}}>
          <Grid item xs={2} />
          <Grid item xs={7}>
            <Typography variant="h1" style={{fontWeight: "bolder"}}>Hi,</Typography>
            <Typography variant="h2" style={{fontWeight: "bolder"}}>My name is <BlueText component="span">Ahmed Siadomar.</BlueText></Typography>
            <Typography variant="h4">
              I am a 2nd year
              <BlueText component="span"> Software Engineering </BlueText>
              student at the University of Alberta. My main interests currently include algorithms, math, and systems.
            </Typography>
          </Grid>
          <Grid item xs={3} />

          <Grid item xs={12} style={{marginTop: "60px"}} />

          <Grid item xs={2} />
          <Grid item xs={7} style={{textAlign: "center"}}>
            <Typography variant="h5" style={{fontStyle: "italic"}}>In case you wanted to cut to the chase, here is my resume:</Typography>
            <IconButton>
              <a href={Resume} target="_blank" rel="noreferrer">
                <ReceiptIcon style={{fontSize: "100px", fill: "#171515"}} />
              </a>
            </IconButton>
            <Typography variant="h5">But if you prefer the website <BlueText component="span">aesthetics, </BlueText>keep scrolling!</Typography>
          </Grid>
          <Grid item xs={3} />
        
        </Grid>
        
        <Grid container spacing={2} style={{width: "100%", margin: 0, marginTop: "50px"}}>
          <Grid item xs={2} />
          <Grid item xs={10}>
            <Typography variant="h2" style={{fontWeight: "bolder"}}><BlueText component="span">Projects:</BlueText></Typography>
          </Grid>
          <Grid item xs={12} style={{height: "30px"}} />
          <Grid item xs={2} />
          <Grid item xs={8} style={{textAlign: "center"}}> 
            <img src="/mathshowdown.png" alt="Math Showdown" width="80%" />
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={2} />
          <Grid item xs={8} style={{textAlign: "center"}}>
            <Typography variant="h4">
              <a href="https://www.mathshowdown.com">
                Math Showdown
              </a>
            </Typography>
            <Typography variant="h6">
              A platform for competing against other competitors in Math contests.
            </Typography>
          </Grid>
          <Grid item xs={2} />         
        </Grid>

        <Grid container spacing={2} style={{width: "100%", margin: 0, marginTop: "70px"}}>
          <Grid item xs={2} />
          <Grid item xs={8} style={{textAlign: "center"}}> 
            <img src="/kattistracker1.png" alt="Chart" width="40%" />
            <img src="/kattistracker2.png" alt="Statistics" width="40%" />
          </Grid>
          <Grid item xs={2} />
          <Grid item xs={2} />
          <Grid item xs={8} style={{textAlign: "center"}}>
            <Typography variant="h4">
              <a href="https://chrome.google.com/webstore/detail/kattis-tracker/labgloilbabpmjphkmkihfamjmnmloaj">
                Kattis Tracker
              </a>
            </Typography>
            <Typography variant="h6">
              A browser extension that tracks your solutions on to problems on Kattis and uploads them to GitHub automatically.
            </Typography>
          </Grid>
          <Grid item xs={2} />         
        </Grid>

        <Grid container spacing={2} style={{width: "100%", margin: 0, marginTop: "50px"}}>
          <Grid item xs={2} />
          <Grid item xs={10}>
            <Typography variant="h2" style={{fontWeight: "bolder"}}><BlueText component="span">Technical Skills:</BlueText></Typography>           
          </Grid>
          <Skills />
        </Grid>

        <Grid container spacing={2} style={{width: "100%", margin: 0, marginTop: "50px"}}>
          <Grid item xs={12} style={{textAlign: "center"}}>
            <Typography variant="h4">
              Like what you see? Feel free to
              <BlueText component="span"> contact </BlueText>
              me using the links below.</Typography>
          </Grid>
        </Grid>
        <Footer />
    </div>
  );
}

export default App;
