import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import GitHubIcon from "@material-ui/icons/GitHub";
import MailIcon from "@material-ui/icons/Mail";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import ReceiptIcon from '@material-ui/icons/Receipt';
import Resume from "../Resume.pdf";

const Footer = (props) => {
    return (
        <BottomNavigation style={{ backgroundColor: "#f7f7f7", marginTop: "30px" }} showLabels>
            <BottomNavigationAction
                component={"a"} href="https://github.com/AhmedSiad" label="GitHub" icon={<GitHubIcon style={{ fill: "#171515" }} />} />

            <BottomNavigationAction
                component={"a"} href="https://www.linkedin.com/in/ahmed-siadomar-93bb231b2/" label="LinkedIn"
                icon={<LinkedInIcon style={{ fill: "#0A66C2" }} />} />

            <BottomNavigationAction
                component={"a"} href="mailto:asiadoma@ualberta.ca" label="Mail" icon={<MailIcon style={{ fill: "#171515" }} />} />

            <BottomNavigationAction
                component={"a"} href={Resume} label="Resume" icon={<ReceiptIcon style={{ fill: "#171515" }} />} />
        </BottomNavigation>
    );
}

export default Footer;